import { CreativeModerations, Moderations } from '@/models/standardAds/moderation';
import { logWarn, logError } from '@/utils/utils';
import { message as AntdMessage } from 'antd';
import {localCache as Cache, CacheNamespace} from '@/pages/utils/cache';

/**
 * A representation of moderation state for one Standard Ads task.
 **/
export class ModerationsTuple {
  moderations: Moderations;
  creativeModerations: CreativeModerations;

  constructor(moderations: Moderations, creativeModerations: CreativeModerations) {
    this.moderations = moderations;
    this.creativeModerations = creativeModerations;
  }
}

/**
 * A serializable representation of moderation states cache stored in LocalStorage.
 * This contains a map of tasks.
 **/
export class ModerationResultCache {
  constructor(map: Map<string, ModerationsTuple>) {
    this.internalMap = map;
  }

  private internalMap: Map<string, ModerationsTuple>;
  static _KEY = 'standard_ads_tasks';

  static fromLocalStorageOrDefault(): ModerationResultCache {
    const str = localStorage.getItem(ModerationResultCache._KEY);
    if (str === null) {
      return new ModerationResultCache(new Map());
    }
    return ModerationResultCache.fromString(str);
  }

  static add(taskId: string, moderations: Moderations, creativeModerations: CreativeModerations): Promise<any> {
    return Cache.SetItem(
      CacheNamespace.StandardAdsTask,
      taskId,
      new ModerationsTuple(moderations, creativeModerations),
    ).catch(reason => {
      AntdMessage.error('Failed to add task to local storage cache. taskId:' + taskId, reason);
      logError('Failed to add task to local storage cache. taskId:' + taskId, reason);
    })
  }

  static delete(taskId: string): Promise<any> {
    return Cache.DeleteItem(CacheNamespace.StandardAdsTask, taskId)
      .catch(reason => {
        AntdMessage.warning('Failed to delete task from local storage.');
        logWarn('Failed to delete task from local storage cache. taskId:' + taskId + " reason: " + reason)
      })
  }

  static get(taskId: string): Promise<ModerationsTuple> {
    return Cache.GetItem(CacheNamespace.StandardAdsTask, taskId)
      .then(result => {
        return result && new ModerationsTuple(
          Moderations.fromObjectMap(result.moderations),
          CreativeModerations.fromObjectMap(result.creativeModerations.moderations),
        )
      }).catch(reason => {
        AntdMessage.warning('Failed to get task from local storage.');
        logWarn('Failed to get task from local storage cache. taskId:' + taskId, reason);
        return null;
      })
  }

  toString(): string {
    return JSON.stringify(this, (key, value) => {
      if (value instanceof Map) {
        return {
          __DATA_TYPE__: 'Map',
          value: Object.fromEntries(value.entries()),
        };
      } else {
        return value;
      }
    });
  }

  static fromString(str: string): ModerationResultCache {
    const moderationResultCacheJson = JSON.parse(str, (key, value) => {
      if (value instanceof Object && value.__DATA_TYPE__ === 'Map') {
        return new Map(Object.entries(value.value));
      } else {
        return value;
      }
    });
    const map = new Map<string, ModerationsTuple>();
    for (const [key, value] of moderationResultCacheJson.internalMap) {
      map.set(
        key,
        new ModerationsTuple(
          Moderations.fromObjectMap(value.moderations),
          CreativeModerations.fromObjectMap(value.creativeModerations.moderations),
        ),
      );
    }
    return new ModerationResultCache(map);
  }
}
