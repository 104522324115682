import type { UserRoleEnum } from '@/models/UserRoleEnum';

export default function (initialState: { currentUser: { authority: string[] } }) {
  let currentAuthority = initialState.currentUser.authority;
  if (!Array.isArray(currentAuthority)) {
    currentAuthority = [currentAuthority];
  }
  const check = (roles: string[] | undefined) =>
    roles ? currentAuthority.some((item) => roles.includes(item)) : true;

  return {
    authorityFilter: (route: { authority: any }) => {
      let authority = route.authority;
      if (!Array.isArray(authority)) {
        authority = [authority];
      }
      return check(authority);
    },
    accessedByRoles: (roles: UserRoleEnum[]) => check(roles?.map((e) => e.toString())),
    accessedByRole: (role: UserRoleEnum) => check([role?.toString()]),
  };
}
