import type { Effect, Reducer } from '@@/plugin-dva/connect';
import type { GetDictionaryRecordSchemasResponse } from '@/pages/services/moderationV3';
import { dynamicAdsV3 } from '@/pages/services/moderationV3';
import type { DictionaryRecordSchema } from '@/models/dictionary';
import { message } from 'antd';

export interface DynamicAdsAllowDenyListStateType {
  recordSchemas: Map<number, DictionaryRecordSchema>;
}

export interface DynamicAdsAllowDenyListModelType {
  namespace: string;
  state: DynamicAdsAllowDenyListStateType;
  effects: {
    fetchRecordSchemas: Effect;
    deleteRecord: Effect;
    createRecord: Effect;
    updateRecord: Effect;
  };
  reducers: {
    save: Reducer<DynamicAdsAllowDenyListStateType>;
  };
}

const DynamicAdsAllowDenyListModel: DynamicAdsAllowDenyListModelType = {
  namespace: 'dynamicAdsAllowDenyList',
  state: {
    recordSchemas: {} as Map<number, DictionaryRecordSchema>,
  },
  effects: {
    *fetchRecordSchemas({ payload }, { call, put }) {
      try {
        const abstractsResponse: GetDictionaryRecordSchemasResponse = yield call(
          dynamicAdsV3.getDictionaryRecordSchemas,
          payload,
        );
        yield put({
          type: 'save',
          payload: abstractsResponse.data,
        });
      } catch (e) {
        message.error('Failed to fetch Dictionary record schemas').then();
      }
    },
    *deleteRecord({ payload }, { call }) {
      try {
        yield call(dynamicAdsV3.deleteDictionaryRecord, payload);
      } catch (e) {
        message.error('Failed to delete a dictionary record').then();
      }
    },
    *createRecord({ payload }, { call }) {
      try {
        yield call(dynamicAdsV3.createDictionaryRecord, payload);
      } catch (e) {
        message.error('Failed to create a dictionary record').then();
      }
    },
    *updateRecord({ payload }, { call }) {
      try {
        yield call(dynamicAdsV3.updateDictionaryRecord, payload);
      } catch (e) {
        message.error('Failed to update a dictionary record').then();
      }
    },
  },
  reducers: {
    save(state, action) {
      const recordSchemasMap = action.payload.reduce((obj, recordSchema) => {
        obj[recordSchema.recordSchemaId] = recordSchema;
        return obj;
      }, {});
      return {
        recordSchemas: recordSchemasMap,
      };
    },
  },
};

export default DynamicAdsAllowDenyListModel;
