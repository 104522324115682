import type { ExtendOptionsInit, ResponseError } from 'umi-request';
import { extend } from 'umi-request';
import { message } from 'antd';
import { logError } from '@/utils/utils';
import type {
  AddUsersToTaskUserGroupRequest,
  CreateTaskPoolRequest,
  CreateTaskUserGroupRequest,
  GetTaskPoolsResponse,
  GetTaskUserGroupsResponse,
  GetTaskUserGroupUsersResponse,
  RemoveUsersFromTaskUserGroupRequest,
  UpdateTaskPoolRequest,
  UpdateTaskUserGroupRequest,
} from '@/pages/services/moderationV3';
import type { TaskPool, TaskUserGroup } from '@/models/task';

/**
 * Handle expected exceptions and return proper message to its caller.
 * For 401 or 403 exceptions, emit the error itself, clear user info and redirect to login page. TODO(MP-666): Call logout endpoint to clear backend session
 * For 5xx exceptions, log errors and report to datadog and to its caller to catch.
 * For other exceptions, emit error log and throw to its caller to catch.
 * **/
const commonErrorHandler = (error: ResponseError) => {
  const { response, data } = error;

  if (response && response.status) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { status, url } = response;
    if (status === 401) {
      message.info('Authentication failed. Please login in again').then();
      window.location.href = `${SN_ACCOUNT_SIGN_IN}${encodeURIComponent(
        window.location.toString(),
      )}`;
    } else if (status === 403) {
      message.warning("You don't have permission.").then();
    }
    if (status >= 500) {
      // server error, log them
      logError(`Request error ${status}: ${url}. ${data}`);
      throw error.data;
    } else {
      // all other errors
      throw error.data;
    }
  } else if (!response) {
    // The request was made but no response was received or error occurs when setting up the request.
    message.error('Please check if your network connection is available.').then();
  }

  return response;
};

function moderateClientOption() {
  const option: ExtendOptionsInit = {
    errorHandler: commonErrorHandler,
    credentials: 'include',
  };
  if (SN_ACCOUNT_ID_HEADER) {
    option.headers = {
      'sn-account-id': SN_ACCOUNT_ID_HEADER,
    };
  }
  return option;
}

const moderateClient = extend(moderateClientOption());
const onlineClient = extend({
  errorHandler: commonErrorHandler,
});

export async function moderationRequest(url: string, method: string = 'GET', params?: any) {
  return moderateClient(`/api/moderation/v2/${url}`, {
    method,
    data: params,
    requestType: 'json',
  });
}

export async function moderationV3Request(url: string, method: string = 'GET', params?: any) {
  return moderateClient(`/api/moderation/v3/${url}`, {
    method,
    data: params,
    requestType: 'json',
  });
}

export async function moderationV3DownloadFileRequest(
  url: string,
  method: string = 'GET',
  fileName: string,
  params?: any,
) {
  return moderateClient(`/api/moderation/v3/${url}`, {
    method,
    data: params,
    responseType: 'blob',
  }).then((res) => {
    const blob = new Blob([res]);
    const objectURL = URL.createObjectURL(blob);
    const btn = document.createElement('a');
    btn.download = fileName;
    btn.href = objectURL;
    btn.click();
    URL.revokeObjectURL(objectURL);
  });
}

export async function downloadCommentReportRequest(
  url: string,
  method: string = 'GET',
  fileName: string,
  params?: any,
) {
  return moderateClient(`/api/moderation/v2/${url}`, {
    method,
    data: params,
    responseType: 'blob',
  }).then((res) => {
    const blob = new Blob([res]);
    const objectURL = URL.createObjectURL(blob);
    let btn = document.createElement('a');
    btn.download = fileName;
    btn.href = objectURL;
    btn.click();
    URL.revokeObjectURL(objectURL);
    btn = null;
  });
}

export async function onlineRequest<T>(url: string, method: string = 'GET', data?: T) {
  return onlineClient(`/api/online/v1/${url}`, {
    method,
    data,
    requestType: 'form',
  });
}

export async function getTaskPools(namespace: string): Promise<GetTaskPoolsResponse> {
  return moderationV3Request(`${namespace}/task-pools`);
}

export async function getTaskPoolByName(
  namespace: string,
  taskPoolName: string,
): Promise<GetTaskPoolsResponse> {
  return moderationV3Request(`${namespace}/task-pools/${taskPoolName}`);
}

export async function createTaskPool(
  namespace: string,
  createTaskPoolRequest: CreateTaskPoolRequest,
): Promise<TaskPool> {
  return moderationV3Request(`${namespace}/task-pools`, 'POST', createTaskPoolRequest);
}

export async function getTaskUserGroups(namespace: string): Promise<GetTaskUserGroupsResponse> {
  return moderationV3Request(`${namespace}/task-user-groups`);
}

export async function updateTaskPool(
  namespace: string,
  taskPoolName: string,
  updateTaskPoolRequest: UpdateTaskPoolRequest,
): Promise<void> {
  return moderationV3Request(
    `${namespace}/task-pools/${taskPoolName}`,
    'PATCH',
    updateTaskPoolRequest,
  );
}

export async function createTaskUserGroup(
  namespace: string,
  createTaskUserGroupRequest: CreateTaskUserGroupRequest,
): Promise<TaskUserGroup> {
  return moderationV3Request(`${namespace}/task-user-groups`, `POST`, createTaskUserGroupRequest);
}

export async function getTaskUserGroupByName(
  namespace: string,
  taskUserGroupName: string,
): Promise<GetTaskUserGroupsResponse> {
  return moderationV3Request(`${namespace}/task-user-groups/${taskUserGroupName}`);
}

export async function updateTaskUserGroup(
  namespace: string,
  taskUserGroupName: string,
  updateTaskUserGroupRequest: UpdateTaskUserGroupRequest,
): Promise<void> {
  return moderationV3Request(
    `${namespace}/task-user-groups/${taskUserGroupName}`,
    'PATCH',
    updateTaskUserGroupRequest,
  );
}

export async function getTaskUserGroupUsers(
  namespace: string,
  taskUserGroupName: string,
): Promise<GetTaskUserGroupUsersResponse> {
  return moderationV3Request(`${namespace}/task-user-groups/${taskUserGroupName}/users`);
}

export async function addUsersToTaskUserGroup(
  namespace: string,
  taskUserGroupName: string,
  request: AddUsersToTaskUserGroupRequest,
): Promise<void> {
  return moderationV3Request(
    `${namespace}/task-user-groups/${taskUserGroupName}/users`,
    'POST',
    request,
  );
}

export async function removeUsersFromTaskUserGroup(
  namespace: string,
  taskUserGroupName: string,
  request: RemoveUsersFromTaskUserGroupRequest,
): Promise<void> {
  return moderationV3Request(
    `${namespace}/task-user-groups/${taskUserGroupName}/users`,
    'DELETE',
    request,
  );
}
