import { message } from 'antd';
import type { Effect } from '@@/plugin-dva/connect';
import { getTaskPools } from '@/pages/utils/service';
import type { DictionaryRecord, DictionaryRecordSchema } from '@/models/dictionary';
import type { AnyAction } from 'redux';
import type { GetTaskPoolsResponse } from '@/pages/services/moderationV3';
import { moderationV3APICommon } from '@/pages/services/moderationV3';

const { getDictionaryData, saveTaskPoolRules } = moderationV3APICommon;

export interface TaskPoolRulesStateType {
  taskPoolRuleModel: TaskPoolRuleModel;
}

export interface TaskPoolRulesModelType {
  namespace: 'taskPoolRules';
  state: TaskPoolRulesStateType;
  effects: {
    fetch: Effect;
    fetchByRegion: Effect;
    save: Effect;
  };
  reducers: NonNullable<unknown>;
}

export interface TaskPoolRuleModel {
  namespace?: string;
  dictionaryName?: string;
  recordSchemaId?: number;
  rules: TaskPoolRule[];
  deletedConditionIds: number[];
  taskPoolOptions: TaskPoolRuleSelectionOption[];
  fieldNameOptions: TaskPoolRuleSelectionOption[];
  matchingOptions: TaskPoolRuleSelectionOption[];
  tierOptions: TaskPoolRuleSelectionOption[];
  showButtons: boolean;
}

export interface TaskPoolRuleSelectionOption {
  label: string;
  value: string;
  isUnary?: boolean;
}

export interface TaskPoolRule {
  taskPool?: string;
  priority: number;
  tier?: string;
  conditions: TaskPoolRuleCondition[];
}

export interface TaskPoolRuleCondition {
  id?: number;
  fieldName?: string;
  criteria?: string;
  values?: string[];
}

const DEFAULT_TASK_POOL_RULE_MODEL: TaskPoolRuleModel = {
  namespace: undefined,
  dictionaryName: undefined,
  recordSchemaId: undefined,
  rules: [],
  deletedConditionIds: [],
  taskPoolOptions: [],
  fieldNameOptions: [],
  matchingOptions: [],
  tierOptions: [],
  showButtons: false,
};

function getTaskPoolRuleDictionaryName(namespace: string): string {
  return namespace + '_task_pool_rules_dict';
}

const standardAdsDictionaryMap = new Map([
  ['JP', 'standard_ads_task_pool_rules_dict'],
  ['US', 'standard_ads_us_task_pool_rules_dict']
]);

const buildTaskPoolOptions = (taskPoolRs: GetTaskPoolsResponse) => {
  return taskPoolRs.data
    .filter((taskPool) => taskPool.type !== 'ESCALATION')
    .map((taskPool) => {
      return { value: taskPool.name, label: taskPool.description };
    });
};

const buildTierOptions = (recordSchemas: DictionaryRecordSchema[]) => {
  const recordSchema: DictionaryRecordSchema = Object.values(recordSchemas)[0];
  return recordSchema?.format.properties.tier?.enum
    .map((tier: any) => {
      return { value: tier, label: formatFromCamelCase(tier) };
    });
};

function formatFromCamelCase(value: string): string {
  return value.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
    return str.toUpperCase();
  });
}

const getRecordSchemaId = (recordSchemas: DictionaryRecordSchema[]) => {
  const recordSchema: DictionaryRecordSchema = Object.values(recordSchemas)[0];
  if (recordSchema) {
    return recordSchema.recordSchemaId;
  } else {
    return undefined;
  }
};

const buildFieldNameOptions = (recordSchemas: DictionaryRecordSchema[]) => {
  const recordSchema: DictionaryRecordSchema = Object.values(recordSchemas)[0];
  return recordSchema?.format.properties.fieldName.enum.map((fieldName: any) => {
    return { value: fieldName, label: formatFromCamelCase(fieldName) };
  });
};

const buildCriteriaOptions = (recordSchemas: DictionaryRecordSchema[]) => {
  const recordSchema: DictionaryRecordSchema = Object.values(recordSchemas)[0];

  return recordSchema?.format.properties.criteria.enum.map((criteria: any) => {
    if (criteria === 'exact') {
      return { value: criteria, label: 'is any of' };
    } else if (criteria === 'partial') {
      return { value: criteria, label: 'has any of' };
    } else if (criteria === 'neg_exact') {
      return { value: criteria, label: 'is NOT any of' };
    } else if (criteria === 'neg_partial') {
      return { value: criteria, label: "don't have" };
    } else if (criteria === 'first_time_review_advertiser') {
      return { value: criteria, label: 'is first review advertiser', isUnary: true };
    }
    return { value: criteria, label: criteria };
  });
};

const buildTaskPoolRules = (records: DictionaryRecord[]) => {
  let rules: TaskPoolRule[] = [];
  Object.values(records)
    .sort((a, b) => (a.value.priority < b.value.priority ? -1 : 1))
    .map((value) => {
      // if part of previous rule, append condition
      if (rules[value.value.priority - 1]) {
        const ruleItem: TaskPoolRule = rules[value.value.priority - 1];
        const conditionItem: TaskPoolRuleCondition = {
          id: value.recordId,
          fieldName: value.value.fieldName,
          criteria: value.value.criteria,
          values: value.value.values,
        };
        ruleItem.conditions = [...ruleItem.conditions, conditionItem];
      } else {
        // else, create rule with condition
        const ruleItem: TaskPoolRule = {
          taskPool: value.groupKey,
          priority: value.value.priority,
          tier: value.value.tier,
          conditions: [
            {
              id: value.recordId,
              fieldName: value.value.fieldName ?? null,
              criteria: value.value.criteria ?? null,
              values: value.value.values ?? [],
            },
          ],
        };
        rules = [...rules, ruleItem];
      }
    });
  return rules;
};

const TaskPoolRulesModel: TaskPoolRulesModelType = {
  namespace: 'taskPoolRules',

  state: {
    taskPoolRuleModel: { ...DEFAULT_TASK_POOL_RULE_MODEL },
  },

  effects: {
    *fetch({ payload: { namespace: namespace } }, { all, call, put }) {
      try {
        if (namespace) {
          const taskPoolRuleDictionaryName = getTaskPoolRuleDictionaryName(namespace);
          const [taskPoolRs, taskPoolRuleDictionary] = yield all([
            call(getTaskPools, namespace),
            call(getDictionaryData, namespace, taskPoolRuleDictionaryName),
          ]);
          yield put({
            type: 'updateTaskPoolRules',
            payload: {
              taskPoolRuleModel: {
                namespace: namespace,
                dictionaryName: taskPoolRuleDictionaryName,
                recordSchemaId: getRecordSchemaId(taskPoolRuleDictionary.recordSchemas),
                rules: buildTaskPoolRules(taskPoolRuleDictionary.records),
                deletedConditionIds: [],
                taskPoolOptions: buildTaskPoolOptions(taskPoolRs),
                fieldNameOptions: buildFieldNameOptions(taskPoolRuleDictionary.recordSchemas),
                matchingOptions: buildCriteriaOptions(taskPoolRuleDictionary.recordSchemas),
                tierOptions: buildTierOptions(taskPoolRuleDictionary.recordSchemas),
                showButtons: true,
              },
            },
          });
        } else {
          yield put({
            type: 'updateTaskPoolRules',
            payload: {
              taskPoolRuleModel: DEFAULT_TASK_POOL_RULE_MODEL,
            },
          });
        }
      } catch (e) {
        message.error('Failed to fetch task pool rules: ' + e);
      }
    },
    *fetchByRegion({ payload: {namespace: namespace, region: region} }, { all, call, put }) {
      try {
        const taskPoolRuleDictionaryName = standardAdsDictionaryMap.get(region);
        const [taskPoolRs, taskPoolRuleDictionary] = yield all([
          call(getTaskPools, namespace),
          call(getDictionaryData, namespace, taskPoolRuleDictionaryName),
        ]);
        yield put({
          type: 'updateTaskPoolRules',
          payload: {
            taskPoolRuleModel: {
              namespace: namespace,
              dictionaryName: taskPoolRuleDictionaryName,
              recordSchemaId: getRecordSchemaId(taskPoolRuleDictionary.recordSchemas),
              rules: buildTaskPoolRules(taskPoolRuleDictionary.records),
              deletedConditionIds: [],
              taskPoolOptions: buildTaskPoolOptions(taskPoolRs),
              fieldNameOptions: buildFieldNameOptions(taskPoolRuleDictionary.recordSchemas),
              matchingOptions: buildCriteriaOptions(taskPoolRuleDictionary.recordSchemas),
              tierOptions: buildTierOptions(taskPoolRuleDictionary.recordSchemas),
              showButtons: true,
            },
          },
        });
      } catch (e) {
        message.error('Failed to fetch task pool rules: ' + e);
      }
    },
    *save(
      {
        payload: {
          namespace: namespace,
          saveTaskPoolRuleRequest: saveTaskPoolRuleRequest,
        },
      },
      { all, call, put },
    ) {
      try {
        const [saveTaskPoolRulesRs, taskPoolRs, taskPoolRuleDictionary] = yield all([
          call(saveTaskPoolRules, namespace, saveTaskPoolRuleRequest),
          call(getTaskPools, namespace),
          call(getDictionaryData, namespace, saveTaskPoolRuleRequest.dictionaryName),
        ]);
        yield put({
          type: 'updateTaskPoolRules',
          payload: {
            taskPoolRuleModel: {
              namespace: namespace,
              dictionaryName: saveTaskPoolRuleRequest.dictionaryName,
              recordSchemaId: saveTaskPoolRuleRequest.recordSchemaId,
              rules: saveTaskPoolRulesRs.rules,
              deletedConditionIds: [],
              taskPoolOptions: buildTaskPoolOptions(taskPoolRs),
              fieldNameOptions: buildFieldNameOptions(taskPoolRuleDictionary.recordSchemas),
              matchingOptions: buildCriteriaOptions(taskPoolRuleDictionary.recordSchemas),
              tierOptions: buildTierOptions(taskPoolRuleDictionary.recordSchemas),
              showButtons: true,
            },
          },
        });
        message.success(
          'Changes to the task pool rules have been saved.\nThe changes will be reflected immediately.',
        );
      } catch (e) {
        message.error('Failed to save task pool rules: ' + e);
      }
    },
  },
  reducers: {
    updateTaskPoolRules(state: TaskPoolRulesStateType, action: AnyAction) {
      return {
        ...state,
        taskPoolRuleModel: action.payload.taskPoolRuleModel || {},
      };
    },
    updateRulesAndDeletedConditions(state: TaskPoolRulesStateType, action: AnyAction) {
      return {
        ...state,
        taskPoolRuleModel: {
          namespace: state.taskPoolRuleModel.namespace,
          dictionaryName: state.taskPoolRuleModel.dictionaryName,
          recordSchemaId: state.taskPoolRuleModel.recordSchemaId,
          rules: action.payload.taskPoolRuleModel.rules,
          deletedConditionIds: action.payload.taskPoolRuleModel.deletedConditionIds,
          taskPoolOptions: state.taskPoolRuleModel.taskPoolOptions,
          fieldNameOptions: state.taskPoolRuleModel.fieldNameOptions,
          matchingOptions: state.taskPoolRuleModel.matchingOptions,
          tierOptions: state.taskPoolRuleModel.tierOptions,
          showButtons: state.taskPoolRuleModel.showButtons,
        },
      };
    },
  },
};

export default TaskPoolRulesModel;
