
interface Cache {
    SetItem(namespace: string, key: any, value: any): Promise<any>
    GetItem(namespace: string, key: any): Promise<any>
    DeleteItem(namespace: string, key: any): Promise<any>
}

type CacheValue = {key: any; value: any}

class LocalCache<T extends string> implements Cache {

    private db: IDBDatabase;

    constructor(db: IDBDatabase) {
        this.db = db
        this.db.onclose = (ev: Event) => console.log("local cache db is closing ", ev)
    }

    private getObjectStorage(objectStorageName: string, mode: IDBTransactionMode) {
        const trans = this.db.transaction([objectStorageName], mode)
        {
            trans.onabort = (e) => console.warn("indexdb transaction aborted", e)
            trans.onerror = (e) => console.error("indexdb transaction error", e)
            trans.oncomplete = (e) => console.log("indexdb transaction completed", e)
        }
        return trans.objectStore(objectStorageName)
    }

    SetItem(namespace: T, key: any, value: any): Promise<any> {
        let data: CacheValue = {key: key, value: value}
        return new Promise<any>(
            (resolve: (value: any) => void, reject: (reason?: any) => void) => {
                const req = this.getObjectStorage(namespace, "readwrite").put(data)
                req.onsuccess = function(ev: Event) {resolve(this.result)}
                req.onerror = function(ev: Event) {reject(ev)}
            }
        )
    }

    GetItem(namespace: T, key: any): Promise<any | undefined> {
        return new Promise<any>(
            (resolve: (value: any) => void, reject: (reason?: any) => void) => {
                const req = this.getObjectStorage(namespace, "readonly").get(key)
                req.onsuccess = function(ev: Event) {resolve(this.result?.value)}
                req.onerror = function(ev: Event) {reject(ev)}
            }
        )
    }

    DeleteItem(namespace: T, key: any): Promise<any> {
        return new Promise<any>(
            (resolve: (value: any) => void, reject: (reason?: any) => void) => {
                const req = this.getObjectStorage(namespace, "readwrite").delete(key)
                req.onsuccess = function(ev: Event) {resolve(this.result)}
                req.onerror = function(ev: Event) {reject(ev)}
            }
        )
    }
}

class dummyCache<T extends string> implements Cache {
    SetItem(namespace: T, key: any, value: any): Promise<any> {
        console.warn("dummy cache set item:", namespace, key, value)
        return new Promise<any>((resolve, reject) => {})
    }

    GetItem(namespace: T, key: any): Promise<any>  {
        console.warn("dummy cache get item:", namespace, key)
        return new Promise<any>((resolve, reject) => {})
    }

    DeleteItem(namespace: T, key: any): Promise<any> {
        console.warn("dummy cache delete item:", namespace, key)
        return new Promise<any>((resolve, reject) => {})
    }
}

enum ObjectStorageName {
    StandardAdsTask = "standard_ads_task"
}

let _defaultCache: Cache = new dummyCache();
{
    const dbName = "sn-moderation"
    const dbVersion = 1

    const dbReq: IDBOpenDBRequest = window.indexedDB.open(dbName, dbVersion)

    dbReq.onupgradeneeded = function(event: IDBVersionChangeEvent) {
        const db = this.result;

        if(event.oldVersion < 1) { // upgrade to version 1
            db.createObjectStore(
                ObjectStorageName.StandardAdsTask, {keyPath: "key"}
            )
        }
    }

    dbReq.onsuccess = function(event: Event) : any {
        const db = this.result;
        db.onversionchange = function(event: IDBVersionChangeEvent){
            console.warn("db ", db.name, " version changed", event)
        }

        _defaultCache = new LocalCache(db)
    }

    dbReq.onerror = function(event: Event): any {
        console.error("open indexeddb request error: ", event)
    }
}

export {_defaultCache as localCache, ObjectStorageName as CacheNamespace}
