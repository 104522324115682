import {
  isCampaignCreativeCarouselRequest,
  isCampaignCreativeImageRequest,
  isCampaignCreativeVideoRequest,
  isItemStandardAdsOf,
} from '@/models/standardAds/reviewRequest';
import type {
  CampaignCreativeCarouselRequest,
  CampaignCreativeImageRequest,
  CampaignCreativeRequest,
  CampaignCreativeVideoRequest,
  CarouselAsset,
  ImageAsset,
  MediaId,
  VideoAsset,
} from '@/models/standardAds/reviewRequest';
import type { ItemStandardAds } from '@/models/task';

export type StandardAdsElement =
  | StandardAdsTextElement
  | StandardAdsImageElement
  | StandardAdsVideoElement
  | StandardAdsCarouselElement;

export interface StandardAdsElementBase {
  keys: number[];
  items?: ItemStandardAds<CampaignCreativeRequest>[];
}

export interface StandardAdsTextElement extends StandardAdsElementBase {
  content: string;
  score?: number;
}

export interface StandardAdsImageElement extends StandardAdsElementBase {
  imageAsset: ImageAsset;
  score?: number;
}

export interface StandardAdsVideoElement extends StandardAdsElementBase {
  videoAsset: VideoAsset;
}

export interface StandardAdsCarouselElement extends StandardAdsElementBase {
  carouselAsset: CarouselAsset;
}

export interface StandardAdsGroupedElements {
  sponsor: StandardAdsTextElement[];
  title: StandardAdsTextElement[];
  body: StandardAdsTextElement[];
  destination: StandardAdsTextElement[];
  image: StandardAdsImageElement[];
  video: StandardAdsVideoElement[];
  carouselTitle: StandardAdsTextElement[];
}

const compareByModeratedAt = (
  lhs: ItemStandardAds<CampaignCreativeRequest>,
  rhs: ItemStandardAds<CampaignCreativeRequest>,
) => {
  const a = lhs.latestModerationHistory?.moderatedAt ?? 0;
  const b = rhs.latestModerationHistory?.moderatedAt ?? 0;
  return b - a;
};

abstract class ElementGrouped<
  K,
  V extends ItemStandardAds<CampaignCreativeRequest>,
  E extends StandardAdsElementBase,
> {
  private items: Map<K, V[]> = new Map();

  abstract keysExtractor(e: V): K[];

  abstract elementExtractor(key: K, base: StandardAdsElementBase, items: V[]): E;

  add(item: V) {
    const keys = this.keysExtractor(item);

    keys.forEach((key) => {
      const items = this.items.get(key) ?? [];
      items.push(item);

      this.items.set(key, items);
    });
  }

  entries(): [K, V[]][] {
    return Array.from(this.items.entries());
  }

  elements(): E[] {
    return this.entries().map(([key, items]) => {
      const elementBase = {
        keys: items.map((i) => i.payload.key),
        items: items.sort(compareByModeratedAt),
      };
      return this.elementExtractor(key, elementBase, items);
    });
  }
}

abstract class TextElementGrouped extends ElementGrouped<
  string,
  ItemStandardAds<CampaignCreativeRequest>,
  StandardAdsTextElement
> {
  elementExtractor(text: string, base: StandardAdsTextElement) {
    return { ...base, content: text };
  }
}

class SponsorElementGrouped extends TextElementGrouped {
  keysExtractor(item: ItemStandardAds<CampaignCreativeRequest>) {
    return [item.payload.sponsor].filter((s) => s.length > 0);
  }
}

class TitleElementGrouped extends ElementGrouped<
  string,
  ItemStandardAds<CampaignCreativeRequest>,
  StandardAdsTextElement
> {
  elementExtractor(
    text: string,
    base: StandardAdsTextElement,
    items: ItemStandardAds<CampaignCreativeRequest>[],
  ) {
    // ML service gives the same score for the same text. Pick the first score.
    return { ...base, content: text, score: items[0].payload.titleScore };
  }

  keysExtractor(item: ItemStandardAds<CampaignCreativeRequest>) {
    return [item.payload.title].filter((s) => s.length > 0);
  }
}

class BodyElementGrouped extends ElementGrouped<
  string,
  ItemStandardAds<CampaignCreativeRequest>,
  StandardAdsTextElement
> {
  elementExtractor(
    text: string,
    base: StandardAdsTextElement,
    items: ItemStandardAds<CampaignCreativeRequest>[],
  ) {
    // ML service gives the same score for the same text. Pick the first score.
    return { ...base, content: text, score: items[0].payload.bodyScore };
  }

  keysExtractor(item: ItemStandardAds<CampaignCreativeRequest>) {
    return [item.payload.body].filter((s) => s.length > 0);
  }
}

class DestinationElementGrouped extends TextElementGrouped {
  keysExtractor(item: ItemStandardAds<CampaignCreativeRequest>) {
    return [item.payload.destination].filter((s) => s.length > 0);
  }
}

abstract class MediaElementGrouped<
  R extends CampaignCreativeRequest,
  E extends StandardAdsElement,
> extends ElementGrouped<MediaId, ItemStandardAds<R>, E> {
  abstract mediaElementExtractor(base: StandardAdsElementBase, asset: R['assets'][0]): E;

  abstract keyExtractor(asset: R['assets'][0]): MediaId;

  keysExtractor(item: ItemStandardAds<R>): MediaId[] {
    const assets: R['assets'][0][] = item.payload.assets;
    return assets.map((a) => this.keyExtractor(a));
  }

  elementExtractor(mediaId: MediaId, base: StandardAdsElementBase, items: ItemStandardAds<R>[]): E {
    const item = items[0];
    if (item == null) throw new Error('should not come here. items should have at least one item.');

    const assets: R['assets'][0][] = item.payload.assets;
    const asset = assets.find((a) => this.keyExtractor(a) === mediaId);
    if (asset == null)
      throw new Error('should not come here. corresponding asset should be found.');

    return this.mediaElementExtractor(base, asset);
  }
}

class ImageElementGrouped extends MediaElementGrouped<
  CampaignCreativeImageRequest | CampaignCreativeCarouselRequest,
  StandardAdsImageElement
> {
  keyExtractor(a: ImageAsset): MediaId {
    return a.imageId;
  }

  mediaElementExtractor(base: StandardAdsElementBase, asset: ImageAsset) {
    return { ...base, imageAsset: asset, score: asset.imageScore };
  }
}

class VideoElementGrouped extends MediaElementGrouped<
  CampaignCreativeVideoRequest,
  StandardAdsVideoElement
> {
  keyExtractor(a: VideoAsset): MediaId {
    return a.videoId;
  }

  mediaElementExtractor(base: StandardAdsElementBase, asset: VideoAsset) {
    return { ...base, videoAsset: asset };
  }
}

class CarouselTitleElementGrouped extends ElementGrouped<
  string,
  ItemStandardAds<CampaignCreativeCarouselRequest>,
  StandardAdsTextElement
> {
  keyExtractor(a: CarouselAsset): MediaId {
    return a.imageId;
  }

  keysExtractor(item: ItemStandardAds<CampaignCreativeCarouselRequest>) {
    return item.payload.assets.map((e) => e.imageTitle);
  }

  elementExtractor(text: string, base: StandardAdsTextElement) {
    return { ...base, content: text };
  }
}

export const groupCampaignCreativeRequests = (
  requests: ItemStandardAds<CampaignCreativeRequest>[],
): StandardAdsGroupedElements => {
  const sponsorGrouped = new SponsorElementGrouped();
  const titleGrouped = new TitleElementGrouped();
  const bodyGrouped = new BodyElementGrouped();
  const destinationGrouped = new DestinationElementGrouped();
  const imageGrouped = new ImageElementGrouped();
  const videoGrouped = new VideoElementGrouped();
  const carouselTitleGrouped = new CarouselTitleElementGrouped();

  requests.forEach((request) => {
    sponsorGrouped.add(request);
    if (request.payload.title) titleGrouped.add(request);
    if (request.payload.body) bodyGrouped.add(request);
    destinationGrouped.add(request);

    if (isItemStandardAdsOf(isCampaignCreativeImageRequest)(request)) {
      imageGrouped.add(request);
    } else if (isItemStandardAdsOf(isCampaignCreativeVideoRequest)(request)) {
      videoGrouped.add(request);
    } else if (isItemStandardAdsOf(isCampaignCreativeCarouselRequest)(request)) {
      imageGrouped.add(request);
      carouselTitleGrouped.add(request);
    }
  });

  return {
    sponsor: sponsorGrouped.elements(),
    title: titleGrouped.elements(),
    body: bodyGrouped.elements(),
    destination: destinationGrouped.elements(),
    image: imageGrouped.elements(),
    video: videoGrouped.elements(),
    carouselTitle: carouselTitleGrouped.elements(),
  };
};
