import type { RejectReasonContent, RejectReasonResponse } from '@/pages/services/moderationV3';

export interface RejectReasons {
  reasons: Map<string, RejectReasonTarget>;
}

export interface RejectReasonTarget {
  label: string;
  children: Map<string, Policy>;
}

interface Policy {
  label: string;
  children: Description[];
}

interface Description {
  label: string;
  value: string;
  url: string;
}

export const convertToRejectReasonStructure = (
  rejectReasons: RejectReasonResponse,
  locale: string,
): RejectReasons => {
  const reasons = new Map();
  const rejectReasonList = rejectReasons.data.sort((a, b) => (a.code > b.code ? 1 : -1));

  for (const rejectReason of rejectReasonList) {
    if (!rejectReason.enabled) continue;

    const content: RejectReasonContent = rejectReason.content[locale];
    const target = reasons.get(content.target) ?? {
      label: content.target,
      children: new Map(),
    };

    const policy = target.children.get(content.policy) ?? {
      label: content.policy,
      children: [],
    };
    policy.children.push({
      label: content.description,
      value: rejectReason.code,
      url: content.url,
    });
    target.children.set(content.policy, policy);
    reasons.set(content.target, target);
  }

  return {
    reasons: reasons,
  };
};

export const generateReviewComment = (rejectReasons: RejectReasons, value: string[][]): string => {
  let reviewComment = '';
  value.forEach((val) => {
    const target = rejectReasons.reasons.get(val[0]);
    const description = target
      ? (target.children.get(val[1]))?.children.find((description) => description.value === val[2])
      : (rejectReasons.reasons.values().next().value.children.get(val[0])).children.find((description) => description.value === val[1]);
    if (description) {
      const append = description.url
        ? `${description.label} ${description.url}`
        : description.label;
      reviewComment = reviewComment.length > 0 ? `${reviewComment},\n${append}` : append;
    }
  });
  return reviewComment;
};

export const rejectReasonCodeToValue = (
  rejectReasons: RejectReasons,
  codes: string[],
): string[][] => {
  const value: string[][] = [];
  if (codes && codes.length > 0) {
    rejectReasons.reasons.forEach((target) =>
      target.children.forEach((policy) =>
        policy.children.forEach((description) => {
          if (codes.includes(description.value)) {
            value.push([target.label, policy.label, description.value]);
          }
        }),
      ),
    );
  }
  return value;
};
