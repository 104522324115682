import { DynamicAdsItem } from '@/pages/dynamicAds/data';
import { Effect, Reducer } from 'umi';
import { getDynamicAd, getSimilarAds } from '@/pages/dynamicAds/service';
import { message } from 'antd';

export interface SimilarAdsStateType {
  current: DynamicAdsItem | undefined;
  similarAds: DynamicAdsItem[];
}

export interface SimilarAdsModelType {
  namespace: string;
  state: SimilarAdsStateType;
  effects: {
    fetchCurrent: Effect;
    fetchSimilarAds: Effect;
  };
  reducers: {
    saveCurrentAd: Reducer<SimilarAdsStateType>;
    saveSimilarAds: Reducer<SimilarAdsStateType>;
  };
}

const SimilarAdsModel: SimilarAdsModelType = {
  namespace: 'similarAds',

  state: {
    similarAds: [],
  },

  effects: {
    *fetchCurrent({ payload }, { call, put }) {
      try {
        const current: DynamicAdsItem = yield call(getDynamicAd, payload);
        yield put({
          type: 'saveCurrentAd',
          payload: current,
        });
      } catch (e) {
        message.error('Fail to fetch the current ad.').then();
      }
    },

    *fetchSimilarAds({ payload }, { call, put }) {
      try {
        const similarAds = yield call(getSimilarAds, payload);
        yield put({
          type: 'saveSimilarAds',
          payload: similarAds,
        });
      } catch (e) {
        message.error('Fail to fetch similar ads.').then();
      }
    },
  },

  reducers: {
    saveCurrentAd(state, action) {
      return {
        ...state,
        current: action.payload.item || {},
        similarAds: state?.similarAds || [],
      };
    },
    saveSimilarAds(state, action) {
      return {
        ...state,
        current: state?.current,
        similarAds: action.payload.ads || {},
      };
    },
  },
};

export default SimilarAdsModel;
