import { TaskStatus } from '@/models/task';
import { logError } from '@/utils/utils';
import { message } from 'antd';
import difference from 'lodash/difference';
import type { Task, ItemGeoLogicAds, ItemDecoded } from '@/models/task';
import { geologicTask } from '@/pages/services/moderationV3';
import { decodeTaskGeoLogicAds } from './decode';
import type { Effect, Reducer } from 'umi';
import type { CreativeModerations, CreativeReviewStatus } from '@/models/geologicAds/moderation';
import type { ModerationInfo } from '@/pages/services/moderationV3';
import { ModerationStatus } from '@/models/enums';

export type GeoLogicInfeedCreativeRequest = {
  id: number;
  name: string;
  advertiserId: number;
  advertiserName: string;
  agencyId: number;
  agencyName: string;
  systemOwnerId: number;
  systemOwnerName: number;
  categoryName?: string;
  title: string;
  body: string;
  sponsor: string;
  callToAction?: string;
  destination: string;
  previewUrl: string;
  createdAt: string;
  updatedAt: string;
  assets: ImageAsset[];
};

export type ImageAsset = {
  url: string;
  width: number;
  height: number;
};

export const getCreativeFromTask = (task: TaskGeoLogicAds) => {
  return task.item.payload;
};

const toModerationStatus = (reviewStatus: CreativeReviewStatus): ModerationStatus => {
  switch (reviewStatus) {
    case 'Approved':
      return ModerationStatus.APPROVED;
    case 'Rejected':
      return ModerationStatus.REJECTED;
    case 'Escalated':
      return ModerationStatus.ESCALATED;
    default:
      throw new Error('invalid review status');
  }
};

const buildModerationInfo = (
  creativeModerations: CreativeModerations,
  items: ItemGeoLogicAds<GeoLogicInfeedCreativeRequest>[],
): ModerationInfo[] => {
  const ret = items.map((item) => {
    const creativeId = item.payload.id;
    const creativeModeration = creativeModerations.get(creativeId);
    if (creativeModeration == null) {
      throw new Error('moderation corresponding to campaign creative not found');
    }

    return {
      itemId: item.itemId,
      moderationStatus: toModerationStatus(creativeModeration.reviewStatus),
      moderationReason: creativeModeration.reviewComment,
      moderationNote: creativeModeration.internalComment,
    };
  });
  return ret;
};

export interface TaskGeoLogicAds extends Omit<Task, 'payload' | 'items'> {
  payload: null;
  // GeoLogicAds task should have one item
  item: ItemGeoLogicAds<GeoLogicInfeedCreativeRequest>;
}

export interface GeologicAdsModerationStateType {
  tasks: TaskGeoLogicAds[];
}

export const isItemGeoLogicAds = (
  e: ItemDecoded,
): e is ItemGeoLogicAds<GeoLogicInfeedCreativeRequest> => e.namespace == 'geologic';

export interface GeologicAdsModerationModelType {
  namespace: 'geologicAdsModeration';
  state: GeologicAdsModerationStateType;
  effects: {
    fetch: Effect;
    submitModeration: Effect;
  };
  reducers: {
    saveTasks: Reducer<GeologicAdsModerationStateType>;
  };
}

// This is a magic number of geologic ads system for distinguishing new and normal criteria creative.
const SMARTNEWS_SYSTEM_OWNER_ID = 41;

const GeologicAdsModerationModel: GeologicAdsModerationModelType = {
  namespace: 'geologicAdsModeration',
  state: {
    tasks: [],
  },

  effects: {
    *fetch(_, { put, call }) {
      const ret = yield call(geologicTask.searchTasks, {
        status: [TaskStatus.IN_PROGRESS, TaskStatus.OPEN],
        includeItemDetails: true,
        pageSize: 1000,
        current: 0,
      });
      const tasks = ret.data
        .map(decodeTaskGeoLogicAds)
        .filter((task: TaskGeoLogicAds | undefined) => {
          if (task === undefined) return false;
          // filter task rather than HyperLocalAds
          const creative = getCreativeFromTask(task);
          if (creative.systemOwnerId !== SMARTNEWS_SYSTEM_OWNER_ID) return task;
          return false;
        });
      yield put({ type: 'saveTasks', payload: tasks });
    },
    *fetchHyperLocalAds(_, { put, call }) {
      const ret = yield call(geologicTask.searchTasks, {
        status: [TaskStatus.IN_PROGRESS, TaskStatus.OPEN],
        includeItemDetails: true,
        pageSize: 1000,
        current: 0,
      });
      const tasks = ret.data
        .map(decodeTaskGeoLogicAds)
        .filter((task: TaskGeoLogicAds | undefined) => {
          if (task === undefined) return false;
          // filter HyperLocalAds task
          const creative = getCreativeFromTask(task);
          if (creative.systemOwnerId === SMARTNEWS_SYSTEM_OWNER_ID) return task;
          return false;
        });
      yield put({ type: 'saveTasks', payload: tasks });
    },
    *submitModeration({ payload }, { call }) {
      const tasks = payload.tasks as TaskGeoLogicAds[];
      const creativeModerations = payload.creativeModerations as CreativeModerations;
      const items = tasks.map((task) => task.item);

      const moderations: ModerationInfo[] = buildModerationInfo(creativeModerations, items);
      try {
        // moderate items
        const successItemIds: string[] = [];
        try {
          while (moderations.length > 0) {
            // maximum batch size is 256
            const data = moderations.splice(0, 255);
            const res = yield call(geologicTask.moderateItemsBatch, { data });
            if (res.status >= 400)
              throw new Error(`moderateItemsBatch() failed with ${res.status}: ${res.data}`);
            successItemIds.concat([...data.map((moderation) => moderation.itemId)]);
          }
        } catch (e) {
          const failedItemIds = difference(
            [...moderations.map((moderation) => moderation.itemId)],
            successItemIds,
          );
          const options = e instanceof Error ? { cause: e, failedItemIds } : { failedItemIds };
          throw new Error('Failed to update items: ' + e, options);
        }

        // tasks closed
        try {
          const res = yield call(geologicTask.updateTasks, {
            taskIds: tasks.map((task) => task.taskId),
            taskStatus: 'CLOSED',
          });
          if (res.status >= 400)
            throw new Error(
              `updateTasks(${tasks.map((task) => task.taskId).join(',')}) failed with ${
                res.status
              }: ${res.data}`,
            );
        } catch (e) {
          const options = e instanceof Error ? { cause: e } : {};
          throw new Error('Failed to update task: ' + e, options);
        }

        message.success('Successfully submitted the moderation result.');
      } catch (e) {
        message.error('Failed to submit moderation: ' + e);
        const context = e instanceof Error ? e : null;
        logError('Failed to submit moderation: ' + e, context);
      }
    },
  },

  reducers: {
    saveTasks(state, { payload: tasks }) {
      return {
        ...state,
        tasks: tasks ?? [],
      };
    },
  },
};

export default GeologicAdsModerationModel;
