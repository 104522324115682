export function objectToParams(obj: any) {
  let str = '';
  Object.keys(obj).forEach((key) => {
    if (obj[key] == undefined) return;
    if (str !== '') {
      str += '&';
    }
    str = `${str + key}=${encodeURIComponent(obj[key])}`;
  });
  return str;
}
