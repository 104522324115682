export enum ModerationStatus {
  UNPROCESSED = 'UNPROCESSED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  AUTO_REJECTED = 'AUTO_REJECTED',
  AUTO_APPROVED = 'AUTO_APPROVED',
  ESCALATED = 'ESCALATED',
  IGNORED = 'IGNORED',
}

export enum Namespace {
  STANDARD_ADS = 'standard_ads',
  DYNAMIC_ADS = 'dynamic_ads',
}

export enum SearchOpType {
  SEARCH = 'search',
  SAMPLE = 'sample',
}

export enum SearchSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}
