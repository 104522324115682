import request from '@/utils/request';
import logo from '@/assets/new-continent.png';
import { moderationV3APICommon } from '@/pages/services/moderationV3';

export async function query(): Promise<any> {
  return request('/api/users');
}

export async function queryCurrentFromRemote() {
  const user = await moderationV3APICommon.getCurrentUser();
  return {
    avatar: logo,
    name: user.name,
    emailAddress: user.email,
    authority: user.roles,
  };
}
