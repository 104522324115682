import { TaskUserGroup } from '@/models/task';
import { Effect, Reducer } from '@@/plugin-dva/connect';
import { message } from 'antd';
import { getTaskUserGroupByName, updateTaskUserGroup } from '@/pages/utils/service';

export interface TaskUserGroupDetailsStateType {
  taskUserGroup: TaskUserGroup;
}

export interface TaskUserGroupDetailsModelType {
  namespace: 'taskUserGroupDetails';
  state: TaskUserGroupDetailsStateType;
  effects: {
    fetch: Effect;
    submitTaskUserGroup: Effect;
  };
  reducers: {
    saveTaskUserGroup: Reducer<TaskUserGroupDetailsStateType>;
    updateTaskUserGroup: Reducer<TaskUserGroupDetailsStateType>;
  };
}

const DEFAULT_TASK_USER_GROUP: TaskUserGroup = {
  id: '-',
  name: '-',
  description: '-',
};

const TaskUserGroupDetailsModel: TaskUserGroupDetailsModelType = {
  namespace: 'taskUserGroupDetails',

  state: {
    taskUserGroup: { ...DEFAULT_TASK_USER_GROUP },
  },

  effects: {
    *fetch(
      { payload: { namespace: namespace, taskUserGroupName: taskUserGroupName } },
      { call, put },
    ) {
      try {
        const { data: taskUserGroup } = yield call(
          getTaskUserGroupByName,
          namespace,
          taskUserGroupName,
        );
        yield put({
          type: 'saveTaskUserGroup',
          payload: {
            id: taskUserGroup.id,
            name: taskUserGroup.name,
            description: taskUserGroup.description,
          },
        });
      } catch (e) {
        message.error('Fail to fetch task task user group: ' + e);
      }
    },
    *submitTaskUserGroup({ payload }, { call, put }) {
      try {
        yield put({
          type: 'updateTaskUserGroup',
          payload: payload.request,
        });
        yield call(
          updateTaskUserGroup,
          payload.namespace,
          payload.taskUserGroupName,
          payload.request,
        );
        message.info('User group updated successfully.');
      } catch (e) {
        message.error('Fail to update user group: ' + e);
      }
    },
  },
  reducers: {
    saveTaskUserGroup(state, action) {
      return {
        ...state,
        taskUserGroup: action.payload || {},
      };
    },
    updateTaskUserGroup(state, action) {
      return {
        ...state,
        taskUserGroup: {
          ...state!!.taskUserGroup,
          ...action.payload,
        },
      };
    },
  },
};

export default TaskUserGroupDetailsModel;
