import {datadogLogs} from '@datadog/browser-logs';
import {ElementPassRate} from '@/pages/services/interface/StandardAdsElementPassRate';

/* eslint no-useless-escape:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[-\/'%;@=\+,.\w_~]*)?\??(?:[-\/\(\)|'*$!&?%:;@=\+,.\w_\[\]\{\}~]*)#?(?:[-\/\(\)'*$!&?#%:;@=\+,.\w_\[\]\{\}~]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

/**
 *  Check if this application is deployed in the server. Note: Both dev or prd environment could return true here.
 *  If you want to find out the development environment, you should check `REACT_APP_ENV`
 **/
export const isDeployedInServer = (): boolean => {
  const { NODE_ENV } = process.env;
  if (NODE_ENV === 'development') {
    // this development means local development.
    return false;
  }
  return true;
};

export const convertAppEnvToDatadogTag = (): string => {
  switch (REACT_APP_ENV) {
    case 'prod':
      return 'prd';
    case 'dev':
      return 'dev';
    default:
      return REACT_APP_ENV;
  }
};

export const logError = (message: string, messageContext?: any): void => {
  if (isDeployedInServer()) {
    datadogLogs.logger.error(message, messageContext);
  }
};

export const logWarn = (message: string, messageContext?: any): void => {
  if (isDeployedInServer()) {
    datadogLogs.logger.warn(message, messageContext);
  }
};

const normalizeString = (v: string) => v.normalize('NFKC');

export const searchStringNormalized =
  <T>(extractor: (e: T) => string) =>
  (e: T, searchText: string): boolean => {
    const text = normalizeString(extractor(e)).toLocaleLowerCase();
    return text.includes(normalizeString(searchText.trim()).toLocaleLowerCase());
  };

export const compareStringNormalized =
  <T>(extractor: (e: T) => string) =>
  (lhs: T, rhs: T): number => {
    const a = normalizeString(extractor(lhs));
    const b = normalizeString(extractor(rhs));
    return a.localeCompare(b);
  };

export function compareElementPassRate(
  a: ElementPassRate | undefined,
  b: ElementPassRate | undefined,
): number {
  const aPassRate = (a?.reviewed ?? 0) === 0 ? 0 : (a?.approved ?? 0) / (a?.reviewed ?? 1);
  const bPassRate = (b?.reviewed ?? 0) === 0 ? 0 : (b?.approved ?? 0) / (b?.reviewed ?? 1);
  return aPassRate === bPassRate ? (a?.approved ?? 0) - (b?.approved ?? 0) : aPassRate - bPassRate;
}

export function convertStringKeyMapToNumberKey(
  map?: Map<string, any>,
): Map<number, any> | undefined {
  if (map === undefined) return undefined;
  const res = new Map();
  for (const [key, value] of map) {
    res.set(Number(key), value);
  }
  return res;
}

// See MP-699 for detailed discussion.
// TODO rethink about how to remove parameters in MP-716
export const removeAnalyticsParameters = (url: string): string => {
  const match = url.match(/[?&]utm_/);
  if (match === null) {
    return url;
  }
  return url.slice(0, match.index);
};

export const extractCommonPart = (destination: string): string => {
  if (isUrl(destination)) {
    return removeAnalyticsParameters(destination);
  }

  return destination;
};

